<template>
  <body>
    <div class="p-3 bg-secondary progress-bar-striped" style="min-height: 10px;">
    </div>
    <div class="jumbotron">
    <section class="py-5 text-left border-bottom">
        <div class="row py-lg-5">
          <div class="col-lg-6 col-md-8 mx-auto">
            <h1 class="fw-light">Ajaib? Tidak juga.</h1>
            <p class="lead text-muted">
              Jadi dari official Whatsapp itu ada yang namanya URL API, atau link API, atau alamat web API, yang bisa dipake buat kirim Whatsapp tanpa perlu kita save. 
              Udah coba generate link nya? Itulah URL API (serta nomor WA yang tertera dibelakangnya) yang akan diakses melalui browser.
            </p>
            <p class="lead text-muted">
              API? Adalah Application Programming Interface, yang merupakan interface yang dapat digunakan oleh program untuk mengakses fitur-fitur yang ada di sistem.
              Sederhananya seperti pelayan (API) yang mengantarkan makanan dari koki (server) kepada pemesan (client, pengguna, user), juga sebaliknya, pelayan yang membawa pesanan menuju koki.
            </p>
            <p class="lead text-muted">
              That's it. Mau aplikasi ini mempermudah pekerjaan mu lagi? Email atau DM ya!
            </p>
            <p class="lead text-muted">
              Jangan lupa instal Whatsapp di smartphone-mu, ya!
            </p>
          </div>
        </div>
    </section>
  </div>
  </body>
</template>

<script>
export default {
  created () {
      document.title = "Dibalik Layar - Kirim WA";
  }
}
</script>

<style>

</style>
