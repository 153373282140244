<template>
   <navigation></navigation>
   <div class="">
      <router-view/>
   </div>
   
</template>

<script>
export default {

}
</script>

<style>
body {
   overflow-x: hidden;
}
</style>
